import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

export const query = graphql`
  query DocumentsForDownloadQuery {
    allStrapiDokumentyDoPobraniaSzkolaPodstawowa {
      edges {
        node {
          pliki {
            id
            name
            url
            ext
            size
          }
          tytul
          id
        }
      }
    }
  }
`

function DocumentsForDownload({ data }) {
  const documents = data.allStrapiDokumentyDoPobraniaSzkolaPodstawowa.edges.map(
    el => {
      const files = el.node.pliki.map(file => (
        <li key={file.id}>
          <a
            href={`${
              file.url.startsWith("http") ? "" : process.env.STRAPI_BASE_URL
            }${file.url}`}
          >
            {file.name} ({`${Math.round(file.size)}KB `}|
            {" " + file.ext.slice(1)})
          </a>
        </li>
      ))

      return (
        <div key={el.node.id} className="filesSection">
          <h2>{el.node.tytul}</h2>
          <ul>{files}</ul>
        </div>
      )
    }
  )
  return (
    <Layout>
      <SEO title="Dokumenty do pobrania - ZSP Nidek" />
      <h1>Dokumenty do pobrania</h1>
      {documents}
    </Layout>
  )
}

export default DocumentsForDownload
